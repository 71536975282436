var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "item" }, [
      _vm._m(0),
      _c(
        "div",
        [
          _c(
            "i-switch",
            {
              attrs: { "true-value": 1, "false-value": 0 },
              on: { "on-change": _vm.changeMessage },
              model: {
                value: _vm.messageOpen,
                callback: function ($$v) {
                  _vm.messageOpen = $$v
                },
                expression: "messageOpen",
              },
            },
            [
              _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                _vm._v("开"),
              ]),
              _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                _vm._v("关"),
              ]),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "title" }, [_vm._v("系统消息")]),
      _c("div", { staticClass: "desc" }, [
        _vm._v("系统消息将以站内信的形式通知"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }